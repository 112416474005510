export const IconColor = [
    "#7477E6",
    "#F2385E",
    "#E5AA1F",
    "#54AFDE",
    "#47C471",
    "#FC8B45",
    "#2652B4",
    "#F52E3F",
    "#9F2EE9",
    "#955D1D",
    "#3F5C72",
    "#7E4367",
    "#245D26",
    "#474747",
];

