// Icones
import FlagIcon from "./Icons/flag.svg";
import NotificationIcon from "./Icons/notification.svg";
import PlusIcon from "./Icons/plus.svg";
import SettingIcon from "./Icons/settings-filled.svg";
import DownArrow from "./Icons/DownArrow.svg";
import WalletGreenIcon from "./Icons/wallet-2.svg";
import WalletRedIcon from "./Icons/wallet.svg";
import BankIcon from "./Icons/bank.svg";
import MoneyBagIcon from "./Icons/moneybag.svg";
import HelpHandicon from "./Icons/helping-hand.svg";
import PercentageIcon from "./Icons/percentage.svg";
import DownloadIcon from "./Icons/download.svg";
import UploadIcon from "./Icons/upload.svg";
import Bag2Icon from "./Icons/bag-2.svg";
import PurplePlus from "./Icons/PurplePlus.svg";
import SearchNormalIcon from "./Icons/search-normal.svg";
import WalletAddIcon from "./Icons/wallet-add.svg";
import WalletAdd1Icon from "./Icons/wallet-add-1.svg";
import WalletAdd2Icon from "./Icons/wallet-add-2.svg";
import WalletAdd3Icon from "./Icons/wallet-add-3.svg";
import ArchiveIcon from "./Icons/archive.svg";
import Archive2Icon from "./Icons/archive (1).svg";
import PersonIcon from "./Icons/Person.svg";
import CalenderIcon from "./Icons/calendar-2.svg";
import PhoneCallIcon from "./Icons/phone-call.svg";
import CheckIcon from "./Icons/Check.svg";
import ClipIcon from "./Icons/Clip path group.svg";
import TimeIcon from "./Icons/time.svg";
import NoteIcon from "./Icons/note-edit-line.svg";
import UserIcone from "./Icons/Group 42.svg";
import EmailIcon from "./Icons/email-line.svg";
import LocationIcone from "./Icons/my-location.svg";
import LockIcone from "./Icons/lock.svg";
import CheckFillIcon from "./Icons/checkmark-filled.svg";
import ArrowbackIcon from "./Icons/arrow_back.svg";
import LogoutIcon from "./Icons/logout.svg";
import PersonalcardIcon from "./Icons/personalcard.svg";
import Transfer from "./Icons/Transfer.svg";
import NextIcon from "./Icons/next.svg";
import PreviousIcone from "./Icons/previous.svg";
import FilterIcon from "./Icons/Filter.svg";
import ExportIcone from "./Icons/file-export.svg";
import HomeIcone from "./Icons/home.svg";
import LanguageIcone from "./Icons/language-hiragana.svg";
import ArrowUpright from "./Icons/arrow-up-right.svg";
import ExpensesIcone from "./Icons/expenses.png";
import IncomeIcone from "./Icons/income.png";
import MenuIcon from "./Icons/menu.svg";
import LoanIcon from "./Icons/image 19.png";
import BankIcone from "./Icons/image 21.png";
import AssetIcon1 from "./Icons/image 25.png";
import AssetIcon2 from "./Icons/image 26.png";
import FaceBookIcon from "./Icons/facebook.png";
import TwitterIcon from "./Icons/twitter.png";
import InstagramIcon from "./Icons/instagram.png";
import SnapChatIcon from "./Icons/brand-snapchat.png";
import ComaUpIcon from "./Icons/comaUp.svg";
import ComaDownIcon from "./Icons/comaDown.svg";

// Logos
import ZLogo from "./Logo/Group251.svg";
import ZincoLogo from "./Logo/Zinco.svg";
import MainLogo from "./Logo/Group 2 (1).svg";

// Images
import Frame19Img from "./Images/Frame 19.png";
import LoginImg from "./Images/loginimg.svg";
import SignUpImg from "./Images/SignupImg.svg";
import dashboardImage from "./Images/image 1.png";
import transferListImage from "./Images/image 22.png";
import payableImage from "./Images/image 2.png";
import recevablesImage from "./Images/image 3.png";
import tookImage from "./Images/image 6.png";
import featureImage from "./Images/image 7.png";
import playstoreImage from "./Images/image 14.png";
import appstoreImage from "./Images/image 15.png";
import SummarySlice from "./Images/image 8.png";
import IncomSclice from "./Images/image 17.png";
import ExpensesSclice from "./Images/image 16.png";
import LoanSlice from "./Images/image 18.png";
import TransferSclice from "./Images/image 20.png";
import AssetSlice from "./Images/image 23.png";
import Avtar1 from "./Images/image 10.png";
import Avtar2 from "./Images/image 11.png";
import Avtar3 from "./Images/image 12.png";
import Avtar4 from "./Images/Ellipse 29.png";
import ExpireImg from "./Images/Group 1414.svg";
import AboutImage from "./Images/Cuengester app_store.png";

// Create Icones
import CUP from "./CreatorIcons/CUP.svg";
import HAND from "./CreatorIcons/HAND.svg";
import HAT from "./CreatorIcons/HAT.svg";
import TV from "./CreatorIcons/TV.svg";
import BALL from "./CreatorIcons/BALL.svg";
import BANK from "./CreatorIcons/BANK.svg";
import CROWN from "./CreatorIcons/CROWN.svg";
import DJ from "./CreatorIcons/DJ.svg";
import DOLLAR from "./CreatorIcons/DOLLAR.svg";
import FORK from "./CreatorIcons/FORK.svg";
import GIFT from "./CreatorIcons/GIFT.svg";
import PARTY from "./CreatorIcons/PARTY.svg";
import PETROL from "./CreatorIcons/PETROL.svg";
import PIGGY from "./CreatorIcons/PIGGY.svg";
import SECURITY from "./CreatorIcons/SECURITY.svg";
import TOOLS from "./CreatorIcons/TOOLS.svg";
import VAN from "./CreatorIcons/VAN.svg";
import WINE from "./CreatorIcons/WINE.svg";
import YOGA from "./CreatorIcons/YOGA.svg";

import BUN from "./CreatorIcons/BUN.svg";
import SWEETS from "./CreatorIcons/SWEETS.svg";
import COFFEE from "./CreatorIcons/COFFEE.svg";
import MEAT from "./CreatorIcons/MEAT.svg";
import CASE from "./CreatorIcons/CASE.svg";
import CART from "./CreatorIcons/CART.svg";
import DELIVERY from "./CreatorIcons/DELIVERY.svg";
import PARCEL from "./CreatorIcons/PARCEL.svg";
import HOME from "./CreatorIcons/HOME.svg";
import COMPANY from "./CreatorIcons/COMPANY.svg";
import WAREHOUSE from "./CreatorIcons/WAREHOUSE.svg";
import WATER from "./CreatorIcons/WATER.svg";
import MOBILE from "./CreatorIcons/MOBILE.svg";
import BABY from "./CreatorIcons/BABY.svg";
import DENTAL from "./CreatorIcons/DENTAL.svg";
import FURNITURE from "./CreatorIcons/FURNITURE.svg";
import HEALTH from "./CreatorIcons/HEALTH.svg";
import SANITIZER from "./CreatorIcons/SANITIZER.svg";
import IRON from "./CreatorIcons/IRON.svg";
import CAKE from "./CreatorIcons/CAKE.svg";
import PILLS from "./CreatorIcons/PILLS.svg";
import SHAKE from "./CreatorIcons/SHAKE.svg";
import DOCTOR from "./CreatorIcons/DOCTOR.svg";
import LAUNDRY from "./CreatorIcons/LAUNDRY.svg";
import CHARGE from "./CreatorIcons/CHARGE.svg";
import WALLET from "./CreatorIcons/WALLET.svg";
import TAXI from "./CreatorIcons/TAXI.svg";
import BUSINESS from "./CreatorIcons/BUSINESS.svg";
import SCOOTER from "./CreatorIcons/SCOOTER.svg";
import CREDIT from "./CreatorIcons/CREDIT.svg";
import TRAIN from "./CreatorIcons/TRAIN.svg";
import MOSQUE from "./CreatorIcons/MOSQUE.svg";
import TRAM from "./CreatorIcons/TRAM.svg";
import STOCK from "./CreatorIcons/STOCK.svg";
import ELECTRICITY from "./CreatorIcons/ELECTRICITY.svg";
import GRAPH from "./CreatorIcons/GRAPH.svg";
import FRUIT from "./CreatorIcons/FRUIT.svg";


const Icone = {
  FlagIcon,
  NotificationIcon,
  PlusIcon,
  SettingIcon,
  DownArrow,
  WalletGreenIcon,
  WalletRedIcon,
  BankIcon,
  MoneyBagIcon,
  HelpHandicon,
  PercentageIcon,
  DownloadIcon,
  UploadIcon,
  Bag2Icon,
  PurplePlus,
  SearchNormalIcon,
  WalletAddIcon,
  WalletAdd1Icon,
  WalletAdd2Icon,
  WalletAdd3Icon,
  ArchiveIcon,
  Archive2Icon,
  PersonIcon,
  CalenderIcon,
  PhoneCallIcon,
  CheckIcon,
  ClipIcon,
  TimeIcon,
  NoteIcon,
  UserIcone,
  EmailIcon,
  LocationIcone,
  LockIcone,
  CheckFillIcon,
  ArrowbackIcon,
  LogoutIcon,
  PersonalcardIcon,
  Transfer,
  NextIcon,
  PreviousIcone,
  FilterIcon,
  ExportIcone,
  HomeIcone,
  LanguageIcone,
  ArrowUpright,
  ExpensesIcone,
  IncomeIcone,
  MenuIcon,
  LoanIcon,
  BankIcone,
  AssetIcon1,
  AssetIcon2,
  FaceBookIcon,
  TwitterIcon,
  InstagramIcon,
  SnapChatIcon,
  ComaUpIcon,
  ComaDownIcon,
};

const Logo = {
  ZincoLogo,
  ZLogo,
  MainLogo,
};

const Images = {
  Frame19Img,
  LoginImg,
  SignUpImg,
  dashboardImage,
  transferListImage,
  payableImage,
  recevablesImage,
  tookImage,
  featureImage,
  playstoreImage,
  appstoreImage,
  SummarySlice,
  IncomSclice,
  ExpensesSclice,
  LoanSlice,
  TransferSclice,
  AssetSlice,
  Avtar1,
  Avtar2,
  Avtar3,
  Avtar4,
  ExpireImg,
  AboutImage,
};

const CreatorIcons = {
  CUP,
  HAND,
  HAT,
  TV,
  PETROL,
  GIFT,
  CROWN,
  SECURITY,
  BANK,
  DJ,
  WINE,
  TOOLS,
  DOLLAR,
  PARTY,
  FORK,
  PIGGY,
  YOGA,
  BALL,
  VAN,
  BUN,
  SWEETS,
  COFFEE,
  MEAT,
  CASE,
  CART,
  DELIVERY,
  PARCEL,
  HOME,
  COMPANY,
  WAREHOUSE,
  WATER,
  MOBILE,
  BABY,
  DENTAL,
  FURNITURE,
  HEALTH,
  SANITIZER,
  IRON,
  CAKE,
  PILLS,
  SHAKE,
  DOCTOR,
  LAUNDRY,
  CHARGE,
  WALLET,
  TAXI,
  BUSINESS,
  SCOOTER,
  CREDIT,
  TRAIN,
  MOSQUE,
  TRAM,
  STOCK,
  ELECTRICITY,
  GRAPH,
  FRUIT,
};

export { Icone, Logo, Images, CreatorIcons };
